import React from 'react';
import styled from 'styled-components';
import { PurchaseCard, PurchaseCardProps } from './PurchaseCard';
import { de } from 'date-fns/locale';

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SectionDescription = styled.div`
    font-size: 1.5rem;
    font-weight: 400;
    color: #999999;
`;

const SectionTitle = styled.div`
    font-size: 2.5rem;
    font-weight: 800;
`;

const CardListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
`;

export interface PaymentSectionProps {
    title: string;
    description: string;
    math: PurchaseCardProps;
    english: PurchaseCardProps;
}

export const PaymentSection = (props: PaymentSectionProps) => {
    const { title, description, math, english } = props;
    return (
        <SectionWrapper>
            <SectionTitleWrapper>
                <SectionDescription>{description}</SectionDescription>
                <SectionTitle>{title}</SectionTitle>
            </SectionTitleWrapper>
            <CardListWrapper>
                <PurchaseCard title={math.title} description={math.description} color={math.color} imageUrl={math.imageUrl} purchaseType={math.purchaseType} />
                <PurchaseCard
                    title={english.title}
                    description={english.description}
                    color={english.color}
                    imageUrl={english.imageUrl}
                    purchaseType={english.purchaseType}
                />
            </CardListWrapper>
        </SectionWrapper>
    );
};
