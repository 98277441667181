import React, { useMemo } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, styled as muiStyled } from '@material-ui/core';
import { ProductRule } from '../payment/ProductRules';
import {
    PRODUCT_RULES_FOR_ACORDIAN,
    PRODUCT_RULES_FOR_ACORDIAN_WITH_TABLET,
    PRODUCT_RULES_FOR_ENVELOPE,
    PRODUCT_RULES_FOR_TEXTBOOK
} from '../../constants/product-rules';
import { MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../constants/constants';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #ffffff;
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
        padding: 2rem 1rem;
    }
`;

const RuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: ${MAX_DESKTOP_CONTENT_WIDTH}px;

    @media (max-width: ${MIDDLE_DESKTOP_WIDTH}px) {
        width: ${MIN_DESKTOP_CONTENT_WIDTH}px;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
    }
`;

const RuleHeader = styled.div`
    font-family: Pretendard;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: -0.03rem;
    text-align: center;
    color: #000000;
`;

const RuleBody = styled.div``;

const AccordionSummaryBox = muiStyled(AccordionSummary)({
    fontFamily: 'Pretendard',
    fontSize: '1.375rem',
    fontWeight: 700,
    lineHeight: '3rem',
    letterSpacing: '-0.03rem',
    textAlign: 'left',
});

interface ProductRulesAccordionProps {
    withTablet?: boolean;
    isTextbook?: boolean;
    isEnvelope?: boolean;
}

export const ProductRulesAccordion = (props: ProductRulesAccordionProps) => {
    const { withTablet, isTextbook, isEnvelope } = props;
    const productRules = useMemo(() => {
        if (isEnvelope) return PRODUCT_RULES_FOR_ENVELOPE;
        if (isTextbook) return PRODUCT_RULES_FOR_TEXTBOOK;
        if (withTablet) return PRODUCT_RULES_FOR_ACORDIAN_WITH_TABLET;
        return PRODUCT_RULES_FOR_ACORDIAN;
    }, [withTablet, isTextbook]);

    return (
        <Wrapper>
            <RuleWrapper>
                <RuleHeader>유의사항</RuleHeader>
                <RuleBody>
                    {productRules.map((rule, ruleKey) => {
                        return (
                            <Accordion key={ruleKey}>
                                <AccordionSummaryBox expandIcon={<ExpandMore />}>{rule.text}</AccordionSummaryBox>
                                <AccordionDetails>
                                    <ProductRule rule={rule} ruleKey={ruleKey} />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </RuleBody>
            </RuleWrapper>
        </Wrapper>
    );
};
