import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductInput, Subject } from '../../constants/config';

const FieldLabel = styled.h4`
    margin: 0.5rem 0rem;
`;

const initialProduct: ProductInput = {
    name: '',
    description: '',
    pageCategory: '',
    originalPrice: 0,
    price: 0,
    duration: 0,
    subjects: [],
    isRecurring: false,
    refundDate: 0,
};

export const EditProductDialog = (props) => {
    const { isEdit, selectedProduct, isOpen, onClose, onSubmit } = props;
    const [product, setProduct] = useState<ProductInput>(initialProduct);

    useEffect(() => {
        if (isEdit) setProduct(selectedProduct ?? initialProduct);
    }, [isEdit, selectedProduct]);

    const onCloseDialog = () => {
        setProduct(initialProduct);
        onClose();
    };

    const onSubmitDialog = () => {
        const isNameFilled = product.name !== '';
        const isOriginalPriceFilled = product.originalPrice !== 0;
        const isPriceFilled = product.price !== 0;
        const isDurationFilled = product.duration !== 0;
        const isSubjectSelected = product.subjects.length !== 0;

        const isEssentialFieldsFilled = isNameFilled && isOriginalPriceFilled && isPriceFilled && isDurationFilled && isSubjectSelected;

        if (!isEssentialFieldsFilled) return alert('필수항목을 확인하고 입력해주세요.');

        onSubmit(product);
        onCloseDialog();
    };

    const onChangeName = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.name = value;
        setProduct(productCopied);
    };

    const onChangeDescription = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.description = value;
        setProduct(productCopied);
    };

    const onChangePageCategory = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.pageCategory = value;
        setProduct(productCopied);
    };

    const onChangeOriginalPrice = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.originalPrice = Number(value);
        setProduct(productCopied);
    };

    const onChangePrice = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.price = Number(value);
        setProduct(productCopied);
    };

    const onChangeDuration = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.duration = Number(value);
        setProduct(productCopied);
    };

    const onChangeRefundDate = (event) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.refundDate = Number(value);
        setProduct(productCopied);
    };

    const onCheckRecurring = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const productCopied = JSON.parse(JSON.stringify(product));
        productCopied.isRecurring = value === 'recurring';
        setProduct(productCopied);
    };

    const onCheckEnglish = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects.push(Subject.ENGLISH);
            setProduct(productCopied);
        } else {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects = productCopied.subjects.filter((subject) => subject !== Subject.ENGLISH);
            setProduct(productCopied);
        }
    };

    const onCheckMath = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects.push(Subject.MATH);
            setProduct(productCopied);
        } else {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects = productCopied.subjects.filter((subject) => subject !== Subject.MATH);
            setProduct(productCopied);
        }
    };

    const onCheckKorean = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects.push(Subject.KOREAN);
            setProduct(productCopied);
        } else {
            const productCopied = JSON.parse(JSON.stringify(product));
            productCopied.subjects = productCopied.subjects.filter((subject) => subject !== Subject.KOREAN);
            setProduct(productCopied);
        }
    };

    return (
        <Dialog fullWidth={true} open={isOpen} onClose={onCloseDialog}>
            <DialogTitle>상품</DialogTitle>
            <DialogContent>
                <FieldLabel>제목*</FieldLabel>
                <TextField onChange={onChangeName} value={product.name} size={'small'} variant={'outlined'} fullWidth={true} />
                <FieldLabel>상세 정보</FieldLabel>
                <TextField onChange={onChangeDescription} value={product.description} size={'small'} variant={'outlined'} fullWidth={true} />
                <FieldLabel>카테고리</FieldLabel>
                <TextField onChange={onChangePageCategory} value={product.pageCategory} size={'small'} variant={'outlined'} fullWidth={true} />
                <FieldLabel>기본 가격*</FieldLabel>
                <TextField
                    onChange={onChangeOriginalPrice}
                    value={product.originalPrice}
                    size={'small'}
                    variant={'outlined'}
                    type={'number'}
                    fullWidth={true}
                />
                <FieldLabel>실제 가격*</FieldLabel>
                <TextField onChange={onChangePrice} value={product.price} size={'small'} variant={'outlined'} type={'number'} fullWidth={true} />
                <FieldLabel>이용 기간*</FieldLabel>
                <TextField onChange={onChangeDuration} value={product.duration} size={'small'} variant={'outlined'} type={'number'} fullWidth={true} />
                <FieldLabel>환불 가능일</FieldLabel>
                <TextField onChange={onChangeRefundDate} value={product.refundDate} size={'small'} variant={'outlined'} type={'number'} fullWidth={true} />
                <FieldLabel>결제 방식</FieldLabel>
                <RadioGroup row={true} onChange={onCheckRecurring} value={product.isRecurring === true ? 'recurring' : 'normal'}>
                    <FormControlLabel label={'일반결제'} value={'normal'} control={<Radio color={'primary'} />} />
                    <FormControlLabel label={'정기결제'} value={'recurring'} control={<Radio color={'primary'} />} />
                </RadioGroup>
                <FieldLabel>과목*</FieldLabel>
                <FormGroup row={true}>
                    <FormControlLabel
                        label={'영어'}
                        control={<Checkbox onChange={onCheckEnglish} color={'primary'} checked={product.subjects.includes(Subject.ENGLISH)} />}
                    />
                    <FormControlLabel
                        label={'수학'}
                        control={<Checkbox onChange={onCheckMath} color={'primary'} checked={product.subjects.includes(Subject.MATH)} />}
                    />
                    <FormControlLabel
                      label={'국어'}
                      control={<Checkbox onChange={onCheckKorean} color={'primary'} checked={product.subjects.includes(Subject.KOREAN)} />}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmitDialog} variant={'contained'} color={'primary'}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};
