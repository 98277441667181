import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/config';

const PurchaseAdvantageSectionWrapper = styled.div`
    background: #ebffee;
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
        padding: 2rem 1rem;
    }
`;

const PurchaseAdvantageSectionItem = styled.img`
    display: block;
    width: ${MAX_DESKTOP_CONTENT_WIDTH}px;

    @media (max-width: ${MIDDLE_DESKTOP_WIDTH}px) {
        width: ${MIN_DESKTOP_CONTENT_WIDTH}px;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
    }
`;

export const PurchaseAdvantageSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseAdvantageSectionWrapper>
            <PurchaseAdvantageSectionItem src={material.desktop.section6} alt="6" />
        </PurchaseAdvantageSectionWrapper>
    );
};
