import React from 'react';
import { FooterDesktop } from '../../components/common/FooterDesktop';
import styled from 'styled-components';
import { MAX_TABLET_WIDTH } from '../../constants/constants';
import { PaymentSectionList } from '../../components/shop/PaymentSectionList';
import { TotalShopSection } from '../../constants/config';

const Wrapper = styled.div`
    font-family: Pretendard;
`;

const DesktopImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    background: linear-gradient(96.2deg, #293534 32.01%, rgba(41, 53, 52, 0) 105.93%), linear-gradient(311.67deg, #10d46c 1.13%, #0da8ff 104.35%),
        linear-gradient(318.19deg, #10d46c 17.91%, #00a64e 91.44%);
`;

const DesktopImage = styled.img`
    display: block;
    width: ${MAX_TABLET_WIDTH}px;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        width: 100%;
        padding: 1rem;
    }
`;

interface PaymentTotalShopProps {
    sections: TotalShopSection[];
}

export const PaymentTotalShop = ({ sections }: PaymentTotalShopProps) => {
    return (
        <Wrapper>
            <DesktopImageWrapper>
                <DesktopImage src="/images/payment-direct/main/desktop/2.png" alt="header" />
            </DesktopImageWrapper>
            <PaymentSectionList sections={sections} />
            <FooterDesktop />
        </Wrapper>
    );
};
