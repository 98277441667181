import { PaymentBillingType, PayMethod } from '../constants/payment';
import { PurchaseType } from '../constants/config';
import { head, isNil } from '@fxts/core';
import { nanoid } from 'nanoid';
import { ProductBillingType } from '../constants/constants';
import { getIamportDisplay } from './card-quota';
import dateformat from 'dateformat';
import { DateTime } from 'luxon';
import { getIsBillingDayPassed, getLastClassDate } from './time';

export type MerchantType = 'ihateflyingbugs' | 'maesian';

export interface IPurchaseWithIamPort {
    products: any[];
    payMethod: PayMethod;
    amount: number;
    buyerName: string;
    buyerTel: string;
    queryParams?: any[];
    token?: string;
    direct?: boolean;
    extension?: boolean;
    // DIRECT
    purchaseType?: PurchaseType;
    // MONTHLY_BILLING
    startDate?: string;
    monthlyBillingDay?: number;
    // 권한 시작일, 종료일이 지정된 경우 연장 결제 여부와 상관없이 이 값을 사용
    // yyyy-mm-dd
    startDateOfClass?: string;
    endDateOfClass?: string;
    merchantType?: MerchantType;
}

const getPgProvider = (merchantType: MerchantType, billingType: PaymentBillingType, payMethod: PayMethod) => {
    if (merchantType === 'ihateflyingbugs') {
        if (billingType === PaymentBillingType.BILLING) return `tosspayments`;
        if (payMethod === 'kakaopay') return 'kakaopay';
        return 'uplus';
    }
    if (merchantType === 'maesian') return 'tosspayments';
    return '';
};

export const purchaseWithIamport = (input: IPurchaseWithIamPort): void => {
    const {
        products,
        payMethod: _payMethod,
        amount: amountOrigin,
        buyerName,
        buyerTel,
        token,
        queryParams,
        purchaseType,
        direct: isDirect = false,
        extension: isExtended = false,
        monthlyBillingDay: monthlyBillingDayOrigin,
        startDate,
        startDateOfClass: startDateOfClassOrigin,
        endDateOfClass: endDateOfClassOrigin,
        merchantType = 'ihateflyingbugs',
    } = input;

    const type = getPaymentBillingType(products);

    const buyerEmail = '';
    const name = products.map((product) => product.name).join(', ');

    const productIds = products.map((product) => product.id);
    const productId = type === PaymentBillingType.NORMAL ? head(productIds) : undefined;
    const pgProvider = getPgProvider(merchantType, type, _payMethod);

    const payMethod = type === PaymentBillingType.BILLING ? 'card' : _payMethod;
    const merchantUid = type === PaymentBillingType.BILLING ? 'issue_billingkey_' + nanoid() : 'merchant_' + nanoid();
    const amount = type === PaymentBillingType.BILLING ? 0 : amountOrigin;

    const customerUid = type === PaymentBillingType.BILLING ? 'customer_' + nanoid() : undefined;
    const monthlyBillingDay = type === PaymentBillingType.BILLING ? monthlyBillingDayOrigin : undefined;
    const startDateOfClass = !isNil(startDateOfClassOrigin)
        ? startDateOfClassOrigin
        : type === PaymentBillingType.BILLING && !isNil(startDate)
        ? DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy-MM-dd')
        : undefined;
    const endDateOfClass = !isNil(endDateOfClassOrigin)
        ? endDateOfClassOrigin
        : type === PaymentBillingType.BILLING && !isNil(startDate) && !isNil(monthlyBillingDayOrigin)
        ? getLastClassDate(new Date(startDate), getIsBillingDayPassed(new Date(startDate), monthlyBillingDayOrigin)).toFormat('yyyy-MM-dd')
        : undefined;

    const customData = { isDirect, isExtended, monthlyBillingDay, startDateOfClass, endDateOfClass, productIds, productId, token, queryParams };

    requestPay(
        {
            pg: pgProvider,
            pay_method: payMethod,
            merchant_uid: merchantUid,
            customer_uid: customerUid,
            amount: amount,
            tax_free: amount,
            name: name,
            buyer_email: buyerEmail,
            buyer_name: buyerName,
            buyer_tel: buyerTel,
            custom_data: customData,
            vbank_due: dateformat(Date.now() + 1000 * 60 * 60 * 2, 'yyyymmddHHMM'),
            ...getIamportDisplay(amount),
        },
        purchaseType,
        merchantType,
        startDateOfClass,
    );
};

const getMerchantCode = (merchatType?: MerchantType) => {
    if (merchatType === 'ihateflyingbugs') return 'imp02579783';
    if (merchatType === 'maesian') return 'imp08020732';
    return 'imp02579783';
};

const requestPay = (body: any, purchaseType?: PurchaseType, merchatType?: MerchantType, startDateOfClass?: string) => {
    const pathname = window.location.pathname;
    const merchantCode = getMerchantCode(merchatType);
    const redirectUrl = new URL(window.origin + `/result?purchase_type=${purchaseType}&path=${pathname}`);

    redirectUrl.searchParams.set('path', pathname);
    if (!isNil(purchaseType)) redirectUrl.searchParams.set('purchase_type', purchaseType);
    if (!isNil(startDateOfClass)) redirectUrl.searchParams.set('start_date_of_class', startDateOfClass);

    (window as any).IMP.init(merchantCode);
    (window as any).IMP.request_pay(
        {
            ...body,
            m_redirect_url: redirectUrl.toString(),
        },
        (rsp) => {
            const impUid = rsp.imp_uid;
            const merchantUid = rsp.merchant_uid;
            const impSuccess = rsp.success;
            redirectUrl.searchParams.set('imp_uid', impUid);
            redirectUrl.searchParams.set('merchant_uid', merchantUid);
            redirectUrl.searchParams.set('imp_success', impSuccess);

            if (isNil(rsp.error_msg)) {
                window.location.href = redirectUrl.toString();
            } else {
                alert(`결제에 실패하였습니다.\n에러내용: ${rsp.error_msg}`);
            }
        },
    );
};

const getPaymentBillingType = (products: any[]): PaymentBillingType => {
    if (products.length > 1) return PaymentBillingType.BILLING;
    if (products.some((item) => item.billingType === ProductBillingType.RECURRING)) {
        return PaymentBillingType.BILLING;
    }
    if (products.some((item) => item.billingType === ProductBillingType.MONTHLY_BILLING)) {
        return PaymentBillingType.BILLING;
    }
    return PaymentBillingType.NORMAL;
};
