import React from 'react';
import { MAX_MOBILE_WIDTH } from '../../constants/constants';
import { PurchaseType } from '../../constants/config';
import styled from 'styled-components';

const CardWrapper = styled.div`
    width: 100%;
    min-width: 10rem;
    max-width: 20rem;
    min-height: 10rem;
    padding: 1rem;
    border-radius: 0.75rem;
    background-color: ${(props) => props.color};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        flex-direction: column;
    }
`;

const CardTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const CardDescription = styled.div`
    font-size: 0.825rem;
    font-weight: 400;
    min-width: fit-content;
    color: #ffffff;
`;

const CardTitle = styled.div`
    font-size: 1.75rem;
    font-weight: 800;
    min-width: fit-content;
    color: #ffffff;
`;

const CardDesciptionButton = styled.div`
    width: fit-content;
    font-size: 1rem;
    padding: 4px 12px;
    border-radius: 1rem;
    background-color: #ffffff;
    color: ${(props) => props.color};

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        display: none;
    }
`;

const CardImage = styled.img`
    width: 136px;
    height: 94px;
    object-fit: contain;
`;

export interface PurchaseCardProps {
    title: string;
    description: string;
    color: string;
    imageUrl: string;
    purchaseType: PurchaseType;
}

export const PurchaseCard = (props: PurchaseCardProps) => {
    const { title, description, color, imageUrl, purchaseType } = props;

    const moveToPurchasePage = () => {
        window.location.href = window.origin + `/purchase?purchase_type=${purchaseType}`;
    };

    return (
        <CardWrapper color={color} onClick={moveToPurchasePage}>
            <CardTitleWrapper>
                <CardDescription>{description}</CardDescription>
                <CardTitle>{title}</CardTitle>
                <CardDesciptionButton color={color}>자세히보기</CardDesciptionButton>
            </CardTitleWrapper>
            <CardImage src={imageUrl} />
        </CardWrapper>
    );
};
