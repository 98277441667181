import React from 'react';

export const FooterMobile = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '24px 16px',
                gap: '16px',
                backgroundColor: '#2F3233',
                fontFamily: '"Noto Sans KR", Sans-serif',
                fontSize: '12px',
                fontWeight: 400,
                color: '#FFFFFF',
            }}
        >
            <img src="/images/payment-direct/main/mobile/ihateflyingbugs.png" alt={'ihateflyingbugs'} width={156} />
            <div>
                <div>아이헤이트플라잉버그스 ㈜</div>
                <div>사업자등록번호 : 101-86-69359 | 대표자 : 박찬용</div>
                <div>학원설립 운영등록번호 : 제5962호 밀당피티(PT)원격학원</div>
                <div>신고기관명 : 서울특별시 남부교육지원청</div>
                <div>통신판매업신고 : 2013-서울마포-1269</div>
                <div>호스팅서비스: 아이헤이트플라잉버그스 ㈜</div>
                <div>주소 : 서울 영등포구 여의대로 108 파크원 타워1, 39층</div>
                <div>고객센터 : 1899-0508 | E-mail : cs@ihateflyingbugs.com</div>
            </div>
            <div>Ⓒ ihateflyingbugs all rights reserved.</div>
        </div>
    );
};
