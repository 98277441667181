import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../../constants/constants';
import { IQuestionAnswerItem, questionListDefault } from './cosntants/questionList';
import { PurchaseConfigureItem } from '../../../constants/config';

const Wrapper = styled.div`
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
`;

const SectionWrapper = styled.div`
    width: ${MAX_DESKTOP_CONTENT_WIDTH}px;
    padding: 4rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    @media (max-width: ${MIDDLE_DESKTOP_WIDTH}px) {
        width: ${MIN_DESKTOP_CONTENT_WIDTH}px;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 1rem;
        gap: 1rem;
    }
`;

const SectionTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SectionTitle = styled.div`
    font-weight: 800;
    font-size: 3rem;
    color: #333333;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 2rem;
    }
`;

const SectionDescription = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
    color: #999999;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionQuestionListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 1.5rem;
    }
`;

const SectionQuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SectionQuestionTitleWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 1rem;
    padding: 0rem 1.5rem;
`;

const SectionQuestionTitle = styled.div`
    font-weight: 800;
    font-size: 1.5rem;
    color: #333333;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionAnswerWrapper = styled.div`
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 0.5rem;
    display: flex;
    padding: 1.5rem;
    align-items: flex-start;
    gap: 1rem;
`;

const SectionQuestionAnswer = styled.div`
    font-weight: 400;
    font-size: 1.25rem;
    color: #333333;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

export const PurchaseFAQSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <Wrapper>
            <SectionWrapper>
                <SectionTitleWrapper>
                    <SectionDescription>과외를 뛰어넘은 PT</SectionDescription>
                    <SectionTitle>자주 묻는 질문</SectionTitle>
                </SectionTitleWrapper>

                <SectionQuestionListWrapper>
                    {material.questions.map((item, key) => {
                        return <QuestionAnswerItem question={item.question} answer={item.answer} key={key} />;
                    })}
                </SectionQuestionListWrapper>
            </SectionWrapper>
        </Wrapper>
    );
};

const QuestionAnswerItem = (props: IQuestionAnswerItem) => {
    const { question, answer } = props;
    return (
        <SectionQuestionWrapper>
            <SectionQuestionTitleWrapper>
                <QuestionIcon />
                <SectionQuestionTitle>{question}</SectionQuestionTitle>
            </SectionQuestionTitleWrapper>
            <SectionAnswerWrapper>
                <AnswerIcon />
                <SectionQuestionAnswer>{answer}</SectionQuestionAnswer>
            </SectionAnswerWrapper>
        </SectionQuestionWrapper>
    );
};

const SectionQuestionTitleIcon = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 36px 36px 0px 36px;
    background-color: #333333;
    display: grid;
    place-items: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.5rem;
    flex-shrink: 0;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 24px;
        height: 24px;
        border-radius: 24px 24px 0px 24px;
        font-size: 1rem;
    }
`;

const QuestionIcon = () => {
    return <SectionQuestionTitleIcon>Q</SectionQuestionTitleIcon>;
};

const SectionQuestionAnswerIcon = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 36px;
    background-color: #c8c8c8;
    display: grid;
    place-items: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.5rem;
    flex-shrink: 0;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        font-size: 1rem;
    }
`;

const AnswerIcon = () => {
    return <SectionQuestionAnswerIcon>A</SectionQuestionAnswerIcon>;
};
