import { produce } from 'immer';
import {
    IQuestionAnswerItem,
    questionListDefault,
    questionListHighSchool1,
    questionListHighSchool2,
    questionListHighSchool3,
    questionListMiddleSchool,
    questionListRefundTwoWeek,
} from '../components/purchase/section/cosntants/questionList';

export enum PaymentDirectType {
    DEFAULT = 'DEFAULT',
    ELEMENTARY = 'ELEMENTARY',
    ELEMENTARY_NEW = 'ELEMENTARY_NEW',
    ELEMENTARY_NEW_WEEKEND = 'ELEMENTARY_NEW_WEEKEND',
    HOME_SHOPPING = 'HOME_SHOPPING',
    HOME_SHOPPING_1 = 'HOME_SHOPPING_1',
    HOME_SHOPPING_2 = 'HOME_SHOPPING_2',
    HOME_SHOPPING_3 = 'HOME_SHOPPING_3',
    HOME_SHOPPING_4 = 'HOME_SHOPPING_4',
    HOME_SHOPPING_5 = 'HOME_SHOPPING_5',
    HOME_SHOPPING_WEEKEND = 'HOME_SHOPPING_WEEKEND',
    HOME_SHOPPING_1_WEEKEND = 'HOME_SHOPPING_1_WEEKEND',
    HOME_SHOPPING_2_WEEKEND = 'HOME_SHOPPING_2_WEEKEND',
    HOME_SHOPPING_3_WEEKEND = 'HOME_SHOPPING_3_WEEKEND',
    HOME_SHOPPING_4_WEEKEND = 'HOME_SHOPPING_4_WEEKEND',
    HOME_SHOPPING_5_WEEKEND = 'HOME_SHOPPING_5_WEEKEND',
    ADDICTIVE = 'ADDICTIVE',
    ADDICTIVE_4 = 'ADDICTIVE_4',
    ADDICTIVE_30 = 'ADDICTIVE_30',
    ADDICTIVE_SUMMER_VACATION = 'ADDICTIVE_SUMMER_VACATION',
    ADDICTIVE_COMPRESSED = 'ADDICTIVE_COMPRESSED',
    ADDICTIVE_MONTHLY_BILLING = 'ADDICTIVE_MONTHLY_BILLING',
    ADDICTIVE_COMPRESSED_MONTHLY_BILLING = 'ADDICTIVE_COMPRESSED_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_TABLET = 'ADDICTIVE_TABLET',
    ADDICTIVE_TABLET_2 = 'ADDICTIVE_TABLET_2',
    ADDICTIVE_TABLET_3 = 'ADDICTIVE_TABLET_3',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR',
}

export enum PaymentDirectDisplayType {
    DEFAULT = 'DEFAULT',
    ELEMENTARY = 'ELEMENTARY',
    ADDICTIVE = 'ADDICTIVE',
    ADDICTIVE_MONTHLY_BILLING = 'ADDICTIVE_MONTHLY_BILLING',
    PURCHASE = 'PURCHASE',
}

export enum PurchaseType {
    ELEMENTARY_ENGLISH = 'ELEMENTARY_ENGLISH',
    ELEMENTARY_MATH = 'ELEMENTARY_MATH',
    ELEMENTARY_KOREAN = 'ELEMENTARY_KOREAN',
    ELEMENTARY_NEW_ENGLISH = 'ELEMENTARY_NEW_ENGLISH',
    ELEMENTARY_NEW_MATH = 'ELEMENTARY_NEW_MATH',
    ELEMENTARY_NEW_KOREAN = 'ELEMENTARY_NEW_KOREAN',
    ELEMENTARY_NEW_ENGLISH_WEEKEND = 'ELEMENTARY_NEW_ENGLISH_WEEKEND',
    ELEMENTARY_NEW_MATH_WEEKEND = 'ELEMENTARY_NEW_MATH_WEEKEND',
    ELEMENTARY_ENGLISH_PAYBACK_20 = 'ELEMENTARY_ENGLISH_PAYBACK_20',
    ELEMENTARY_MATH_PAYBACK_20 = 'ELEMENTARY_MATH_PAYBACK_20',
    ELEMENTARY_ENGLISH_SPECIAL_03 = 'ELEMENTARY_ENGLISH_SPECIAL_03',
    ELEMENTARY_MATH_SPECIAL_03 = 'ELEMENTARY_MATH_SPECIAL_03',
    ELEMENTARY_ENGLISH_SPECIAL_06 = 'ELEMENTARY_ENGLISH_SPECIAL_06',
    ELEMENTARY_MATH_SPECIAL_06 = 'ELEMENTARY_MATH_SPECIAL_06',
    MIDDLE_MATH = 'MIDDLE_MATH',
    MIDDLE_ENGLISH = 'MIDDLE_ENGLISH',
    HIGH_MATH = 'HIGH_MATH',
    HIGH_ENGLISH = 'HIGH_ENGLISH',
    HOME_SHOPPING_MIDDLE_MATH = 'HOME_SHOPPING_MIDDLE_MATH',
    HOME_SHOPPING_MIDDLE_ENGLISH = 'HOME_SHOPPING_MIDDLE_ENGLISH',
    HOME_SHOPPING_HIGH_MATH = 'HOME_SHOPPING_HIGH_MATH',
    HOME_SHOPPING_HIGH_ENGLISH = 'HOME_SHOPPING_HIGH_ENGLISH',
    HOME_SHOPPING_MIDDLE_MATH_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_WEEKEND',
    ADDICTIVE_MIDDLE_MATH = 'ADDICTIVE_MIDDLE_MATH',
    ADDICTIVE_MIDDLE_ENGLISH = 'ADDICTIVE_MIDDLE_ENGLISH',
    ADDICTIVE_MIDDLE_MATH_30 = 'ADDICTIVE_MIDDLE_MATH_30',
    ADDICTIVE_MIDDLE_ENGLISH_30 = 'ADDICTIVE_MIDDLE_ENGLISH_30',
    ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION = 'ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION',
    ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION = 'ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION',
    ADDICTIVE_MIDDLE_MATH_4 = 'ADDICTIVE_MIDDLE_MATH_4',
    ADDICTIVE_MIDDLE_ENGLISH_4 = 'ADDICTIVE_MIDDLE_ENGLISH_4',
    ADDICTIVE_MATH_COMPRESSED = 'ADDICTIVE_MATH_COMPRESSED',
    ADDICTIVE_ENGLISH_COMPRESSED = 'ADDICTIVE_ENGLISH_COMPRESSED',
    ADDICTIVE_TABLET = 'ADDICTIVE_TABLET',
    ADDICTIVE_TABLET_2 = 'ADDICTIVE_TABLET_2',
    ADDICTIVE_TABLET_3 = 'ADDICTIVE_TABLET_3',
    ADDICTIVE_MONTHLY_BILLING = 'ADDICTIVE_MONTHLY_BILLING',
    ADDICTIVE_COMPRESSED_MONTHLY_BILLING = 'ADDICTIVE_COMPRESSED_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR',
    HOME_SHOPPING_MIDDLE_MATH_1 = 'HOME_SHOPPING_MIDDLE_MATH_1',
    HOME_SHOPPING_MIDDLE_ENGLISH_1 = 'HOME_SHOPPING_MIDDLE_ENGLISH_1',
    HOME_SHOPPING_HIGH_MATH_1 = 'HOME_SHOPPING_HIGH_MATH_1',
    HOME_SHOPPING_HIGH_ENGLISH_1 = 'HOME_SHOPPING_HIGH_ENGLISH_1',
    HOME_SHOPPING_MIDDLE_MATH_2 = 'HOME_SHOPPING_MIDDLE_MATH_2',
    HOME_SHOPPING_MIDDLE_ENGLISH_2 = 'HOME_SHOPPING_MIDDLE_ENGLISH_2',
    HOME_SHOPPING_HIGH_MATH_2 = 'HOME_SHOPPING_HIGH_MATH_2',
    HOME_SHOPPING_HIGH_ENGLISH_2 = 'HOME_SHOPPING_HIGH_ENGLISH_2',
    HOME_SHOPPING_MIDDLE_MATH_3 = 'HOME_SHOPPING_MIDDLE_MATH_3',
    HOME_SHOPPING_MIDDLE_ENGLISH_3 = 'HOME_SHOPPING_MIDDLE_ENGLISH_3',
    HOME_SHOPPING_HIGH_MATH_3 = 'HOME_SHOPPING_HIGH_MATH_3',
    HOME_SHOPPING_HIGH_ENGLISH_3 = 'HOME_SHOPPING_HIGH_ENGLISH_3',
    HOME_SHOPPING_MIDDLE_MATH_4 = 'HOME_SHOPPING_MIDDLE_MATH_4',
    HOME_SHOPPING_MIDDLE_ENGLISH_4 = 'HOME_SHOPPING_MIDDLE_ENGLISH_4',
    HOME_SHOPPING_HIGH_MATH_4 = 'HOME_SHOPPING_HIGH_MATH_4',
    HOME_SHOPPING_HIGH_ENGLISH_4 = 'HOME_SHOPPING_HIGH_ENGLISH_4',
    HOME_SHOPPING_MIDDLE_MATH_5 = 'HOME_SHOPPING_MIDDLE_MATH_5',
    HOME_SHOPPING_MIDDLE_ENGLISH_5 = 'HOME_SHOPPING_MIDDLE_ENGLISH_5',
    HOME_SHOPPING_HIGH_MATH_5 = 'HOME_SHOPPING_HIGH_MATH_5',
    HOME_SHOPPING_HIGH_ENGLISH_5 = 'HOME_SHOPPING_HIGH_ENGLISH_5',
    HOME_SHOPPING_MIDDLE_MATH_1_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_1_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_1_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_1_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_1_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_1_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_1_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_1_WEEKEND',
    HOME_SHOPPING_MIDDLE_MATH_2_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_2_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_2_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_2_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_2_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_2_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_2_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_2_WEEKEND',
    HOME_SHOPPING_MIDDLE_MATH_3_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_3_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_3_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_3_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_3_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_3_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_3_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_3_WEEKEND',
    HOME_SHOPPING_MIDDLE_MATH_4_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_4_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_4_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_4_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_4_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_4_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_4_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_4_WEEKEND',
    HOME_SHOPPING_MIDDLE_MATH_5_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_5_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_5_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_5_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_5_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_5_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_5_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_5_WEEKEND',
    TOTAL_SHOP_MIDDLE_MATH = 'TOTAL_SHOP_MIDDLE_MATH',
    TOTAL_SHOP_MIDDLE_ENGLISH = 'TOTAL_SHOP_MIDDLE_ENGLISH',
    TOTAL_SHOP_HIGH_1_MATH = 'TOTAL_SHOP_HIGH_1_MATH',
    TOTAL_SHOP_HIGH_1_ENGLISH = 'TOTAL_SHOP_HIGH_1_ENGLISH',
    TOTAL_SHOP_HIGH_2_MATH = 'TOTAL_SHOP_HIGH_2_MATH',
    TOTAL_SHOP_HIGH_2_ENGLISH = 'TOTAL_SHOP_HIGH_2_ENGLISH',
    TOTAL_SHOP_HIGH_3_MATH = 'TOTAL_SHOP_HIGH_3_MATH',
    TOTAL_SHOP_HIGH_3_ENGLISH = 'TOTAL_SHOP_HIGH_3_ENGLISH',
    TOTAL_SHOP_MIDDLE_MATH_WEEKEND = 'TOTAL_SHOP_MIDDLE_MATH_WEEKEND',
    TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND = 'TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND',
    TOTAL_SHOP_HIGH_MATH_WEEKEND = 'TOTAL_SHOP_HIGH_MATH_WEEKEND',
    TOTAL_SHOP_HIGH_ENGLISH_WEEKEND = 'TOTAL_SHOP_HIGH_ENGLISH_WEEKEND',
    TOTAL_SHOP_MIDDLE_MATH_PAYBACK_20 = 'TOTAL_SHOP_MIDDLE_MATH_PAYBACK_20',
    TOTAL_SHOP_MIDDLE_ENGLISH_PAYBACK_20 = 'TOTAL_SHOP_MIDDLE_ENGLISH_PAYBACK_20',
    TOTAL_SHOP_HIGH_1_MATH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_1_MATH_PAYBACK_20',
    TOTAL_SHOP_HIGH_1_ENGLISH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_1_ENGLISH_PAYBACK_20',
    TOTAL_SHOP_HIGH_2_MATH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_2_MATH_PAYBACK_20',
    TOTAL_SHOP_HIGH_2_ENGLISH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_2_ENGLISH_PAYBACK_20',
    TOTAL_SHOP_HIGH_3_MATH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_3_MATH_PAYBACK_20',
    TOTAL_SHOP_HIGH_3_ENGLISH_PAYBACK_20 = 'TOTAL_SHOP_HIGH_3_ENGLISH_PAYBACK_20',
    TOTAL_SHOP_MIDDLE_MATH_WEEKEND_PAYBACK_20 = 'TOTAL_SHOP_MIDDLE_MATH_WEEKEND_PAYBACK_20',
    TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND_PAYBACK_20 = 'TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND_PAYBACK_20',
    TOTAL_SHOP_HIGH_MATH_WEEKEND_PAYBACK_20 = 'TOTAL_SHOP_HIGH_MATH_WEEKEND_PAYBACK_20',
    TOTAL_SHOP_HIGH_ENGLISH_WEEKEND_PAYBACK_20 = 'TOTAL_SHOP_HIGH_ENGLISH_WEEKEND_PAYBACK_20',
    TOTAL_SHOP_NEW_MIDDLE_MATH = 'TOTAL_SHOP_NEW_MIDDLE_MATH',
    TOTAL_SHOP_NEW_MIDDLE_ENGLISH = 'TOTAL_SHOP_NEW_MIDDLE_ENGLISH',
    TOTAL_SHOP_NEW_HIGH_1_MATH = 'TOTAL_SHOP_NEW_HIGH_1_MATH',
    TOTAL_SHOP_NEW_HIGH_1_ENGLISH = 'TOTAL_SHOP_NEW_HIGH_1_ENGLISH',
    TOTAL_SHOP_NEW_HIGH_2_MATH = 'TOTAL_SHOP_NEW_HIGH_2_MATH',
    TOTAL_SHOP_NEW_HIGH_2_ENGLISH = 'TOTAL_SHOP_NEW_HIGH_2_ENGLISH',
    TOTAL_SHOP_NEW_HIGH_3_MATH = 'TOTAL_SHOP_NEW_HIGH_3_MATH',
    TOTAL_SHOP_NEW_HIGH_3_ENGLISH = 'TOTAL_SHOP_NEW_HIGH_3_ENGLISH',
    TOTAL_SHOP_NEW_MIDDLE_MATH_WEEKEND = 'TOTAL_SHOP_NEW_MIDDLE_MATH_WEEKEND',
    TOTAL_SHOP_NEW_MIDDLE_ENGLISH_WEEKEND = 'TOTAL_SHOP_NEW_MIDDLE_ENGLISH_WEEKEND',
    TOTAL_SHOP_NEW_HIGH_MATH_WEEKEND = 'TOTAL_SHOP_NEW_HIGH_MATH_WEEKEND',
    TOTAL_SHOP_NEW_HIGH_ENGLISH_WEEKEND = 'TOTAL_SHOP_NEW_HIGH_ENGLISH_WEEKEND',
}

export interface IPaymentDirectPage {
    type: PaymentDirectType;
    displayType: PaymentDirectDisplayType;
    path: string;
    purchases: PurchaseType[];
    images?: IPaymentImage[];
}

export enum ImageType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export interface IPaymentImage {
    name: string;
    url: {
        [ImageType.DESKTOP]: string;
        [ImageType.MOBILE]: string;
    };
}

export const getPaymentDirectPage = (paymentDirectType: PaymentDirectType) => {
    return PaymentDirectPages.find((page) => page.type === paymentDirectType);
};

export const getPaymentDirectPageByPurchaseType = (purchaseType: PurchaseType) => {
    return PaymentDirectPages.find((page) => page.purchases.find((purhcase) => purhcase === purchaseType));
};

export const getPaymentDirectImage = (page: IPaymentDirectPage, name: string, type: ImageType): string => {
    const image = page.images?.find((image) => image.name === name)?.url[type] ?? '';
    return image;
};

export const PaymentDirectPages: IPaymentDirectPage[] = [
    {
        type: PaymentDirectType.DEFAULT,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct',
        purchases: [PurchaseType.MIDDLE_MATH, PurchaseType.MIDDLE_ENGLISH, PurchaseType.HIGH_MATH, PurchaseType.HIGH_ENGLISH],
    },
    {
        type: PaymentDirectType.ELEMENTARY_NEW,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/new',
        purchases: [PurchaseType.ELEMENTARY_NEW_ENGLISH, PurchaseType.ELEMENTARY_NEW_MATH, PurchaseType.ELEMENTARY_NEW_KOREAN],
    },
    {
        type: PaymentDirectType.ELEMENTARY_NEW_WEEKEND,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/weekend/new',
        purchases: [PurchaseType.ELEMENTARY_NEW_ENGLISH_WEEKEND, PurchaseType.ELEMENTARY_NEW_MATH_WEEKEND],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH,
            PurchaseType.HOME_SHOPPING_HIGH_MATH,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_1,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/1',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_1,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_1,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_1,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_1,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_2,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/2',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_2,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_2,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_2,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_2,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_3,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/3',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_3,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_3,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_3,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_3,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_4,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/4',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_4,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_4,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_4,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_4,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_5,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/5',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_5,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_5,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_5,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_1_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/1/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_1_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_1_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_1_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_1_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_2_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/2/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_2_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_2_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_2_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_2_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_3_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/3/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_3_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_3_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_3_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_3_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_4_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/4/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_4_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_4_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_4_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_4_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_5_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/5/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_5_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_5_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_5_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_4,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/4',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_4, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_4],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-4.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-4.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-4-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-4-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_30,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/30',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_30, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_30],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-30.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-30.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-30-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-30-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_SUMMER_VACATION,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/summer-vacation',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-summer-vacation.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-summer-vacation.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-summer-vacation-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-summer-vacation-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_COMPRESSED,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/compressed',
        purchases: [PurchaseType.ADDICTIVE_MATH_COMPRESSED, PurchaseType.ADDICTIVE_ENGLISH_COMPRESSED],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-30.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-30.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-30-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-30-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/compressed/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/middle-school/english/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/middle-school/math/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/high-school/english/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.ADDICTIVE_MONTHLY_BILLING,
        path: '/direct/addictive/high-school/math/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_TABLET,
        displayType: PaymentDirectDisplayType.PURCHASE,
        path: '/purchase?purchase_type=ADDICTIVE_TABLET',
        purchases: [PurchaseType.ADDICTIVE_TABLET],
    },
    {
        type: PaymentDirectType.ADDICTIVE_TABLET_2,
        displayType: PaymentDirectDisplayType.PURCHASE,
        path: '/purchase?purchase_type=ADDICTIVE_TABLET_2',
        purchases: [PurchaseType.ADDICTIVE_TABLET_2],
    },
    {
        type: PaymentDirectType.ADDICTIVE_TABLET_3,
        displayType: PaymentDirectDisplayType.PURCHASE,
        path: '/purchase?purchase_type=ADDICTIVE_TABLET_2',
        purchases: [PurchaseType.ADDICTIVE_TABLET_2],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR,
        displayType: PaymentDirectDisplayType.PURCHASE,
        path: '/purchase?purchase_type=ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR],
    },
    {
        type: PaymentDirectType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR,
        displayType: PaymentDirectDisplayType.PURCHASE,
        path: '/purchase?purchase_type=ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR',
        purchases: [PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR],
    },
];

export interface IPurchaseConfigure {
    [purchaseType: string]: PurchaseConfigureItem;
}

export interface PurchaseConfigureItem {
    color: string;
    paymentType: number;
    isMultiplePayment?: boolean;
    questions: IQuestionAnswerItem[];
    desktop: PurchaseSections;
    mobile: PurchaseSections;
}

interface PurchaseSections {
    section1?: string;
    section2?: string;
    section3?: string;
    section4?: string;
    section5?: string;
    section6?: string;
    section7?: string;
}

export const PurchaseConfigure: IPurchaseConfigure = {
    [PurchaseType.ELEMENTARY_ENGLISH]: {
        color: '#FFE920',
        paymentType: 53,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-elementary-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-elementary-english.png',
            section3: '/images/payment-direct/purchase/desktop/3-elementary-english.png',
            section4: '/images/payment-direct/purchase/desktop/4-elementary-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/desktop/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/desktop/7-elementary-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-elementary-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-elementary-english.png',
            section3: '/images/payment-direct/purchase/mobile/3-elementary-english.png',
            section4: '/images/payment-direct/purchase/mobile/4-elementary-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/mobile/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/mobile/7-elementary-english.png',
        },
    },
    [PurchaseType.ELEMENTARY_MATH]: {
        color: '#49D55D',
        paymentType: 127,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-elementary-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-elementary-math.png',
            section3: '/images/payment-direct/purchase/desktop/3-elementary-english.png',
            section4: '/images/payment-direct/purchase/desktop/4-elementary-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/desktop/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/desktop/7-elementary-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-elementary-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-elementary-math.png',
            section3: '/images/payment-direct/purchase/mobile/3-elementary-english.png',
            section4: '/images/payment-direct/purchase/mobile/4-elementary-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/mobile/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/mobile/7-elementary-english.png',
        },
    },
    [PurchaseType.ELEMENTARY_KOREAN]: {
        color: '#45D8FA',
        paymentType: 1314,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-elementary-korean.png',
            section2: '/images/payment-direct/purchase/desktop/2-elementary-math.png',
            section3: '/images/payment-direct/purchase/desktop/3-elementary-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/desktop/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/desktop/7-elementary-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-elementary-korean.png',
            section2: '/images/payment-direct/purchase/mobile/2-elementary-math.png',
            section3: '/images/payment-direct/purchase/mobile/3-elementary-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-elementary-english.png',
            section6: '/images/payment-direct/purchase/mobile/6-elementary-english.png',
            section7: '/images/payment-direct/purchase/mobile/7-elementary-english.png',
        },
    },
    [PurchaseType.MIDDLE_MATH]: {
        color: '#28D05D',
        paymentType: 32,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.MIDDLE_ENGLISH]: {
        color: '#FF9800',
        paymentType: 33,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HIGH_MATH]: {
        color: '#007DFF',
        paymentType: 34,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HIGH_ENGLISH]: {
        color: '#FF505F',
        paymentType: 35,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },

    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH]: {
        color: '#28D05D',
        paymentType: 55,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH]: {
        color: '#FF9800',
        paymentType: 56,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH]: {
        color: '#007DFF',
        paymentType: 57,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH]: {
        color: '#FF505F',
        paymentType: 58,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_WEEKEND]: {
        color: '#28D05D',
        paymentType: 1004,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-3.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-3.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND]: {
        color: '#FF9800',
        paymentType: 1005,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-3.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-3.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_WEEKEND]: {
        color: '#007DFF',
        paymentType: 1006,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-3.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-3.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_WEEKEND]: {
        color: '#FF505F',
        paymentType: 1007,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-3.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-3.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_MATH]: {
        color: '#0E2F21',
        paymentType: 80,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-math.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-math.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_ENGLISH]: {
        color: '#0E2F21',
        paymentType: 81,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-english.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_MATH_30]: {
        color: '#0E2F21',
        paymentType: 1000,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-math.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-math.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_30]: {
        color: '#0E2F21',
        paymentType: 1001,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-english.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION]: {
        color: '#0E2F21',
        paymentType: 1002,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-math.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-math.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION]: {
        color: '#0E2F21',
        paymentType: 1003,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-english.png',
        },
    },
    [PurchaseType.ADDICTIVE_MATH_COMPRESSED]: {
        color: '#0E2F21',
        paymentType: 1066,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-math.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-math.png',
        },
    },
    [PurchaseType.ADDICTIVE_ENGLISH_COMPRESSED]: {
        color: '#0E2F21',
        paymentType: 1067,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-english.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-english.png',
        },
    },
    [PurchaseType.ADDICTIVE_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1051,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1068,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1338,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1339,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1342,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1343,
        questions: questionListDefault,
        isMultiplePayment: true,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_MATH_4]: {
        color: '#0E2F21',
        paymentType: 1040,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-5.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-5.png',
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_4]: {
        color: '#0E2F21',
        paymentType: 1041,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-5.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-5.png',
        },
    },
    [PurchaseType.ADDICTIVE_TABLET]: {
        color: '#0E2F21',
        paymentType: 1042,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/desktop/2-5.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/mobile/2-5.png',
        },
    },
    [PurchaseType.ADDICTIVE_TABLET_2]: {
        color: '#0E2F21',
        paymentType: 1052,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/desktop/2-5.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/mobile/2-5.png',
        },
    },
    [PurchaseType.ADDICTIVE_TABLET_3]: {
        color: '#0E2F21',
        paymentType: 1053,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/desktop/2-5.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-addictive-tablet.png',
            section2: '/images/payment-direct/purchase/mobile/2-5.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_1]: {
        color: '#28D05D',
        paymentType: 1008,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_1]: {
        color: '#FF9800',
        paymentType: 1009,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_1]: {
        color: '#007DFF',
        paymentType: 1010,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_1]: {
        color: '#FF505F',
        paymentType: 1011,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_2]: {
        color: '#28D05D',
        paymentType: 1012,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_2]: {
        color: '#FF9800',
        paymentType: 1013,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_2]: {
        color: '#007DFF',
        paymentType: 1014,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_2]: {
        color: '#FF505F',
        paymentType: 1015,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_3]: {
        color: '#28D05D',
        paymentType: 1016,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_3]: {
        color: '#FF9800',
        paymentType: 1017,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_3]: {
        color: '#007DFF',
        paymentType: 1018,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_3]: {
        color: '#FF505F',
        paymentType: 1019,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_4]: {
        color: '#28D05D',
        paymentType: 1032,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_4]: {
        color: '#FF9800',
        paymentType: 1033,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_4]: {
        color: '#007DFF',
        paymentType: 1034,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_4]: {
        color: '#FF505F',
        paymentType: 1035,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_5]: {
        color: '#28D05D',
        paymentType: 1043,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_5]: {
        color: '#FF9800',
        paymentType: 1044,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_5]: {
        color: '#007DFF',
        paymentType: 1045,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5]: {
        color: '#FF505F',
        paymentType: 1046,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5]: {
        color: '#FF505F',
        paymentType: 1046,
        questions: questionListRefundTwoWeek,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2-2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-2.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2-2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-2.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH]: {
        color: '#1CD8AC',
        paymentType: 1054,
        questions: questionListMiddleSchool,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH]: {
        color: '#FEC844',
        paymentType: 1055,
        questions: questionListMiddleSchool,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_MATH]: {
        color: '#5BBEFF',
        paymentType: 1056,
        questions: questionListHighSchool1,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH]: {
        color: '#FF9065',
        paymentType: 1057,
        questions: questionListHighSchool1,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_MATH]: {
        color: '#797DFA',
        paymentType: 1058,
        questions: questionListHighSchool2,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH]: {
        color: '#FF779F',
        paymentType: 1059,
        questions: questionListHighSchool2,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_MATH]: {
        color: '#4983F5',
        paymentType: 1060,
        questions: questionListHighSchool3,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH]: {
        color: '#D16FF4',
        paymentType: 1061,
        questions: questionListHighSchool3,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND]: {
        color: '#1CD8AC',
        paymentType: 1062,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND]: {
        color: '#FEC844',
        paymentType: 1063,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND]: {
        color: '#5BBEFF',
        paymentType: 1064,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND]: {
        color: '#FF9065',
        paymentType: 1065,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH_PAYBACK_20]: {
        color: '#1CD8AC',
        paymentType: 1054,
        questions: questionListMiddleSchool,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_PAYBACK_20]: {
        color: '#FEC844',
        paymentType: 1055,
        questions: questionListMiddleSchool,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_MATH_PAYBACK_20]: {
        color: '#5BBEFF',
        paymentType: 1056,
        questions: questionListHighSchool1,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH_PAYBACK_20]: {
        color: '#FF9065',
        paymentType: 1057,
        questions: questionListHighSchool1,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_MATH_PAYBACK_20]: {
        color: '#797DFA',
        paymentType: 1058,
        questions: questionListHighSchool2,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH_PAYBACK_20]: {
        color: '#FF779F',
        paymentType: 1059,
        questions: questionListHighSchool2,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_MATH_PAYBACK_20]: {
        color: '#4983F5',
        paymentType: 1060,
        questions: questionListHighSchool3,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH_PAYBACK_20]: {
        color: '#D16FF4',
        paymentType: 1061,
        questions: questionListHighSchool3,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND_PAYBACK_20]: {
        color: '#1CD8AC',
        paymentType: 1062,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND_PAYBACK_20]: {
        color: '#FEC844',
        paymentType: 1063,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-middle-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-middle-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-middle-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-middle-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND_PAYBACK_20]: {
        color: '#5BBEFF',
        paymentType: 1064,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-math.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-math.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-math.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-math.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND_PAYBACK_20]: {
        color: '#FF9065',
        paymentType: 1065,
        questions: questionListDefault,
        desktop: {
            section1: '/images/payment-direct/purchase/desktop/1-high-english.png',
            section2: '/images/payment-direct/purchase/desktop/2.png',
            section3: '/images/payment-direct/purchase/desktop/3.png',
            section4: '/images/payment-direct/purchase/desktop/4-high-english.png',
            section5: '/images/payment-direct/purchase/desktop/5-payback-20.png',
            section6: '/images/payment-direct/purchase/desktop/6.png',
            section7: '/images/payment-direct/purchase/desktop/7.png',
        },
        mobile: {
            section1: '/images/payment-direct/purchase/mobile/1-high-english.png',
            section2: '/images/payment-direct/purchase/mobile/2.png',
            section3: '/images/payment-direct/purchase/mobile/3.png',
            section4: '/images/payment-direct/purchase/mobile/4-high-english.png',
            section5: '/images/payment-direct/purchase/mobile/5-payback-20.png',
            section6: '/images/payment-direct/purchase/mobile/6.png',
            section7: '/images/payment-direct/purchase/mobile/7.png',
        },
    },
};
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_1_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_1], (draft) => {
    draft.paymentType = 1020;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_1_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_1], (draft) => {
    draft.paymentType = 1021;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_1_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_1], (draft) => {
    draft.paymentType = 1022;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_1_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_1], (draft) => {
    draft.paymentType = 1023;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});

PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_2_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_2], (draft) => {
    draft.paymentType = 1024;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_2_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_2], (draft) => {
    draft.paymentType = 1025;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_2_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_2], (draft) => {
    draft.paymentType = 1026;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_2_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_2], (draft) => {
    draft.paymentType = 1027;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});

PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_3_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_3], (draft) => {
    draft.paymentType = 1028;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_3_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_3], (draft) => {
    draft.paymentType = 1029;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_3_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_3], (draft) => {
    draft.paymentType = 1030;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_3_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_3], (draft) => {
    draft.paymentType = 1031;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_4_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_4], (draft) => {
    draft.paymentType = 1036;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_4_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_4], (draft) => {
    draft.paymentType = 1037;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_4_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_4], (draft) => {
    draft.paymentType = 1038;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_4_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_4], (draft) => {
    draft.paymentType = 1039;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-4.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-4.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_5_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_MATH_5], (draft) => {
    draft.paymentType = 1047;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_5_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_5], (draft) => {
    draft.paymentType = 1048;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_5_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_MATH_5], (draft) => {
    draft.paymentType = 1049;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5_WEEKEND] = produce(PurchaseConfigure[PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_5], (draft) => {
    draft.paymentType = 1050;
    draft.desktop.section2 = '/images/payment-direct/purchase/desktop/2-3.png';
    draft.mobile.section2 = '/images/payment-direct/purchase/mobile/2-3.png';
});
PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH_PAYBACK_20] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.desktop.section5 = '/images/payment-direct/purchase/desktop/5-elementary-payback-20.png';
    draft.mobile.section5 = '/images/payment-direct/purchase/mobile/5-elementary-payback-20.png';
});
PurchaseConfigure[PurchaseType.ELEMENTARY_MATH_PAYBACK_20] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.desktop.section5 = '/images/payment-direct/purchase/desktop/5-elementary-payback-20.png';
    draft.mobile.section5 = '/images/payment-direct/purchase/mobile/5-elementary-payback-20.png';
});
PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH_SPECIAL_03] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1071;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_MATH_SPECIAL_03] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1072;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH_SPECIAL_06] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1069;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_MATH_SPECIAL_06] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1070;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_MIDDLE_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1086;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_MIDDLE_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1083;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_1_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_MATH], (draft) => {
    draft.paymentType = 1092;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_1_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH], (draft) => {
    draft.paymentType = 1090;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_2_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_MATH], (draft) => {
    draft.paymentType = 1087;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_2_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH], (draft) => {
    draft.paymentType = 1084;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_3_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_MATH], (draft) => {
    draft.paymentType = 1088;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_3_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH], (draft) => {
    draft.paymentType = 1085;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_MIDDLE_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND], (draft) => {
    draft.paymentType = 1095;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_MIDDLE_ENGLISH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND], (draft) => {
    draft.paymentType = 1093;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND], (draft) => {
    draft.paymentType = 1096;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_NEW_HIGH_ENGLISH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND], (draft) => {
    draft.paymentType = 1094;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_MATH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1082;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_ENGLISH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1081;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_KOREAN] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_KOREAN], (draft) => {
    draft.paymentType = 1314;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH_SPECIAL_06], (draft) => {
    draft.paymentType = 1098;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_ENGLISH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH_SPECIAL_06], (draft) => {
    draft.paymentType = 1097;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1347;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1348;
});

export enum PaymentPageOverallType {
    NEW = 'NEW',
    EXTENSION = 'EXTENSION',
}

export interface ProductOptionInput {
    isRefundable: boolean;
    productGroups: ProductGroupInput[];
    viewType?: string;
    startDateOfClass?: string;
    endDateOfClass?: string;
}

export interface ProductGroupInput {
    title: string;
    attendTime: string;
    hasExtraCharge: boolean;
    lastAttendTime: string;
    products: ProductInput[];
    orderIndex: number;
}

export interface ProductInput {
    id?: number;
    name: string;
    description: string;
    pageCategory: string;
    originalPrice: number;
    price: number;
    duration: number;
    refundDate: number;
    isRecurring: boolean;
    subjects: Subject[];
}

export enum Subject {
    MATH = 'MATH',
    ENGLISH = 'ENGLISH',
    KOREAN = 'KOREAN',
}

export interface TotalShopSection {
    title: string;
    description: string;
    math: Section;
    english: Section;
}

export interface Section {
    title: string;
    description: string;
    color: string;
    imageUrl: string;
    purchaseType: PurchaseType;
}

export const TotalShopNewSectionList: TotalShopSection[] = [
    {
        title: '초등 · 중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#1CD8AC',
            imageUrl: '/images/shop/icon-middle-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_MIDDLE_MATH,
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FEC844',
            imageUrl: '/images/shop/icon-middle-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_MIDDLE_ENGLISH,
        },
    },
    {
        title: '고1 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고1수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#5BBEFF',
            imageUrl: '/images/shop/icon-high-1-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_1_MATH,
        },
        english: {
            title: '고1영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF9065',
            imageUrl: '/images/shop/icon-high-1-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_1_ENGLISH,
        },
    },
    {
        title: '고2 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고2수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#797DFA',
            imageUrl: '/images/shop/icon-high-2-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_2_MATH,
        },
        english: {
            title: '고2영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            imageUrl: '/images/shop/icon-high-2-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_2_ENGLISH,
        },
    },
    {
        title: '고3 전용 PT',
        description: '성공적인 입시를 향한 첫 걸음',
        math: {
            title: '고3수학PT',
            description: '수학 인생점수 만들기',
            color: '#4983F5',
            imageUrl: '/images/shop/icon-high-3-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_3_MATH,
        },
        english: {
            title: '고3영어PT',
            description: '영어 인생점수 만들기',
            color: '#D16FF4',
            imageUrl: '/images/shop/icon-high-3-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_3_ENGLISH,
        },
    },
];

export const TotalShopNewWeekendSectionList: TotalShopSection[] = [
    {
        title: '중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#1CD8AC',
            imageUrl: '/images/shop/icon-middle-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_MIDDLE_MATH_WEEKEND,
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FEC844',
            imageUrl: '/images/shop/icon-middle-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_MIDDLE_ENGLISH_WEEKEND,
        },
    },
    {
        title: '고등학생 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#5BBEFF',
            imageUrl: '/images/shop/icon-high-1-math.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_MATH_WEEKEND,
        },
        english: {
            title: '영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF9065',
            imageUrl: '/images/shop/icon-high-1-english.png',
            purchaseType: PurchaseType.TOTAL_SHOP_NEW_HIGH_ENGLISH_WEEKEND,
        },
    },
];
