import { styled as muiStyled } from '@material-ui/core/styles';
import { Button, Dialog } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { PurchaseDirectInfoContextStore } from '../../../contexts/purchase-direct.context';
import { DateTime } from 'luxon';
import { getIsBillingDayPassed, getLastClassDate, getMonthDates, getNextBillingDate } from '../../../utils/time';
import styled from 'styled-components';
import { MAX_DESKTOP_CONTENT_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../../constants/constants';
import { isNil } from '@fxts/core';

const IntroductionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IntroductionInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    flex-wrap: wrap;
    width: 100%;
    font-size: 1.25rem;
    @media screen and (min-width: 1280px) {
        font-size: 1.5rem;
        width: ${MIN_DESKTOP_CONTENT_WIDTH}px;
    }
    @media screen and (min-width: 1600px) {
        font-size: 1.75rem;
        width: ${MAX_DESKTOP_CONTENT_WIDTH}px;
    }
`;

const IntroductionButton = muiStyled(Button)({
    boxSizing: 'border-box',
    backgroundColor: '#4FFE7B',
    color: '#000000',
    borderRadius: '0.5rem',
    padding: '0.5rem 1rem',
    flexGrow: 1,
    fontFamily: 'Pretendard',
    fontWeight: 700,
    lineHeight: '145%',
    letterSpacing: '-1.4px',
    fontSize: 'inherit',
});

const PriceIntroductionDialogWrapper = styled.div`
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-family: Pretendard;
    font-size: 1rem;
`;

const PriceIntroductionTitleSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const PriceIntroductionTitle = styled.span`
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 140.5%;
    letter-spacing: -2px;
`;

const PriceIntroductionDescription = styled.div`
    font-size: 0.875rem;
    letter-spacing: -0.5px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const PriceIntroductionDescriptionItem = styled.span`
    color: #444444;
    font-weight: 500;
`;

const PriceIntroductionCalendarSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.825rem;
`;

const CalendarSection = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: stretch;
    gap: 1rem 0px;
`;

const CalendarMonth = styled.div`
    color: #0e0e0e;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
    letter-spacing: -1.5px;
`;

const CalendarDayItem = styled.div`
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
`;

const CalendarUpperDayItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DayLine = styled.div`
    width: 95%;
    height: 2px;
    background-color: #acacac;
`;

const DayText = styled.div`
    text-align: center;
    margin: 4px;
    color: ${(props) => (props.color ? props.color : '')};
`;

const DayTypeText = styled.div`
    width: max-content;
    font-size: 0.625rem;
    font-weight: 600;
    color: ${(props) => (props.color ? props.color : '#0e0e0e')};
`;

const SelectedDayBelt = styled.div`
    width: 100%;
    height: 1rem;
    background-color: rgba(102, 248, 134, 0.39);
`;

export const PurcahseMultiplePaymentIntroductionSection = () => {
    const { firstDateOfClass, monthlyBillingDay } = useContext(PurchaseDirectInfoContextStore);
    const [isPriceDialogOpen, setIsPriceDialogOpen] = useState<boolean>(false);
    const [isTabletDialogOpen, setIsTabletDialogOpen] = useState<boolean>(false);

    const nextMonthDateOfClass = DateTime.fromJSDate(firstDateOfClass).plus({ month: 1 }).toJSDate();
    const isPassedBillingDay = getIsBillingDayPassed(firstDateOfClass, monthlyBillingDay);

    const openPriceDialog = () => {
        if (isNil(firstDateOfClass)) return alert('개강일을 지정해주세요.');
        if (isNil(monthlyBillingDay)) return alert('정기결제일을 지정해주세요.');
        setIsPriceDialogOpen(true);
    };

    const closePriceDialog = () => {
        setIsPriceDialogOpen(false);
    };

    const openTabletDialog = () => {
        setIsTabletDialogOpen(true);
    };

    const closeTabletDialog = () => {
        setIsTabletDialogOpen(false);
    };

    return (
        <>
            <IntroductionWrapper>
                <IntroductionInnerWrapper>
                    <IntroductionButton onClick={openPriceDialog}>수강료 산출 기준 안내</IntroductionButton>
                    <IntroductionButton onClick={openTabletDialog}>아이패드 대여 안내</IntroductionButton>
                </IntroductionInnerWrapper>
            </IntroductionWrapper>
            <Dialog open={isPriceDialogOpen} onClose={closePriceDialog}>
                <PriceIntroductionDialogWrapper>
                    <PriceIntroductionTitleSection>
                        <div>
                            <PriceIntroductionTitle>결제하는 수강료는 이렇게 산출돼요!</PriceIntroductionTitle>
                        </div>
                        <PriceIntroductionDescription>
                            <PriceIntroductionDescriptionItem>
                                · 개강일이 정기결제일 이전인 경우에는 <b>이번 달</b>의 수업료가 결제돼요.
                            </PriceIntroductionDescriptionItem>
                            <PriceIntroductionDescriptionItem>
                                · 개강일이 정기결제일과 같거나 이후인 경우에는 <b>이번 달과 다음 달</b>의 수업료가 결제돼요.
                            </PriceIntroductionDescriptionItem>
                            <PriceIntroductionDescriptionItem>
                                · 매달 정기 결제일에 <b>다음 달</b>의 수업료가 자동으로 결제돼요.
                            </PriceIntroductionDescriptionItem>
                        </PriceIntroductionDescription>
                        <PriceIntroductionDescription>
                            <PriceIntroductionDescriptionItem>
                                · 개강일: {DateTime.fromJSDate(firstDateOfClass).toFormat('yyyy년MM월dd일')}
                            </PriceIntroductionDescriptionItem>
                            <PriceIntroductionDescriptionItem>
                                · 수강기간: {DateTime.fromJSDate(firstDateOfClass).toFormat('yyyy년MM월dd일')} ~{' '}
                                {getLastClassDate(firstDateOfClass, isPassedBillingDay).toFormat('yyyy년MM월dd일')}
                            </PriceIntroductionDescriptionItem>
                            <PriceIntroductionDescriptionItem>
                                · 다음 정기결제일: {getNextBillingDate(firstDateOfClass, monthlyBillingDay).toFormat('yyyy년MM월dd일')}
                            </PriceIntroductionDescriptionItem>
                        </PriceIntroductionDescription>
                    </PriceIntroductionTitleSection>

                    <PriceIntroductionCalendarSection>
                        <Calendar date={firstDateOfClass} />
                        {isPassedBillingDay && <Calendar date={nextMonthDateOfClass} />}
                    </PriceIntroductionCalendarSection>
                </PriceIntroductionDialogWrapper>
            </Dialog>
            <Dialog open={isTabletDialogOpen} onClose={closeTabletDialog}>
                <img style={{ display: 'block', width: '100%' }} src={'/images/payment-direct/purchase/mobile/2-5.png'} alt="tablet rent introduction" />
            </Dialog>
        </>
    );
};

const Calendar = (props: any) => {
    const { date } = props;
    const { firstDateOfClass, monthlyBillingDay } = useContext(PurchaseDirectInfoContextStore);
    const lastDate = getLastClassDate(firstDateOfClass, getIsBillingDayPassed(firstDateOfClass, monthlyBillingDay));

    const dates = getMonthDates(date);
    const monthName = `${date.getFullYear()}년 ${date.getMonth() + 1}월`;

    return (
        <>
            <CalendarMonth>{monthName}</CalendarMonth>
            <CalendarSection>
                {dates.map((item, index) => {
                    const day = item.getDate();
                    const isSelected =
                        DateTime.fromJSDate(item).startOf('day').diff(DateTime.fromJSDate(firstDateOfClass).startOf('day')).milliseconds >= 0 &&
                        DateTime.fromJSDate(item).diff(lastDate).milliseconds <= 0;
                    const types = DateTime.fromJSDate(firstDateOfClass).startOf('day').equals(DateTime.fromJSDate(item).startOf('day'))
                        ? ['FIRST_CLASS_DAY']
                        : [];
                    const isThisMonth = item.getMonth() === date.getMonth();
                    const dayOfWeek = item.getDay();
                    const color = ((isThisMonth, dayOfWeek) => {
                        if (!isThisMonth) return '#ACACAC';
                        if (dayOfWeek === 6) return '#5AACE4';
                        if (dayOfWeek === 0) return '#E82222';
                        return '#0e0e0e';
                    })(isThisMonth, dayOfWeek);

                    return (
                        <CalendarDayItem key={index}>
                            <CalendarUpperDayItem>
                                <DayLine />
                                <DayText color={color}>{day}</DayText>
                                <div>
                                    {types.some((type) => type === 'FIRST_CLASS_DAY') && (
                                        <DayTypeText color={'#A200BC'}>
                                            <span>개강일</span>
                                        </DayTypeText>
                                    )}
                                </div>
                            </CalendarUpperDayItem>
                            {isSelected && <SelectedDayBelt />}
                        </CalendarDayItem>
                    );
                })}
            </CalendarSection>
        </>
    );
};
