import React from 'react';

const Footer = (props) => {
    return (
        <div
            style={{
                width: '100%',
                color: '#FFFFFF',
                backgroundColor: '#3C3D3C',
                fontSize: props.isMobile ? 12 : 14,
                textAlign: 'center',
                padding: '30px',
            }}
        >
            ihateflyingbugs co.,ltd
            <br />
            사업자등록번호 : 101-86-69359 | 대표이사: 박찬용
            <br />
            통신판매업신고 : 2013-서울마포-1269
            <br />
            주소 : 서울 영등포구 여의대로 108 파크원 타워1, 39층
            <br />
            고객센터 : 1899-0508 | E-mail : cs@ihateflyingbugs.com
            <br />
            &copy; ihateflyingbugs all rights reserved.
            <br />
        </div>
    );
};

export default Footer;
