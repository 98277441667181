import { TotalShopSection } from '../../constants/config';
import { PaymentSection } from './PaymentSection';
import React from 'react';
import { MAX_TABLET_WIDTH } from '../../constants/constants';
import styled from 'styled-components';

const SectionListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SectionList = styled.div`
    width: ${MAX_TABLET_WIDTH}px;
    padding: 3rem 0px;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        grid-template-columns: 1fr;
        padding: 2rem 1rem;
    }
`;

interface PaymentSectionListProps {
    sections: TotalShopSection[];
}

export const PaymentSectionList = ({ sections }: PaymentSectionListProps) => {
    return (
        <SectionListWrapper>
            <SectionList>
                {sections.map((item) => {
                    return <PaymentSection title={item.title} description={item.description} math={item.math} english={item.english} />;
                })}
            </SectionList>
        </SectionListWrapper>
    );
};
