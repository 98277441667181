import { getPaymentDirectPageByPurchaseType, PurchaseType } from '../../../constants/config';
import { Button, styled as muiStyled } from '@material-ui/core';
import React from 'react';
import { isEmpty, isNil } from '@fxts/core';
import { validateFirstDateOfClass } from '../../../utils/validate';

const CustomButton = muiStyled(Button)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    lineHeight: '40px',
    padding: '0.5rem 2.5rem',
    fontWeight: 700,
    fontSize: '1.5rem',
});

export const ButtonToReturnPayment = (props) => {
    const { rePays, purchaseType } = props;

    const redirectLink = getRedirectLink(purchaseType);

    if (isNil(redirectLink)) return null;
    return (
        <CustomButton fullWidth={true} style={{ color: '#FFFFFF', background: '#000000' }} onClick={() => window.open(redirectLink, '_blank')?.focus()}>
            {rePays ? '추가 결제하기' : '상품 페이지로 돌아가기'}
        </CustomButton>
    );
};

export const ButtonToAddCustomerCenter = () => {
    return (
        <CustomButton
            fullWidth={true}
            style={{ color: '#000000', background: '#FAE100' }}
            onClick={() => window.open('https://pf.kakao.com/_Ypfeb', '_blank')?.focus()}
        >
            <img src={'/images/payment-direct/result/talk.png'} alt={'talk'} width={40} height={40} />
            밀당PT 고객센터 추가하기
        </CustomButton>
    );
};

const getRedirectLink = (purchaseType: PurchaseType): string | null => {
    const startDateOfClass: string | null = (() => {
        const value = decodeURIComponent(new URLSearchParams(window.location.search).get('start_date_of_class') ?? '');
        if (isEmpty(value)) return null;
        if (new Date(value).toString() === 'Invalid Date') return null;
        if (!validateFirstDateOfClass(new Date(value))) return null;

        return value;
    })();

    const page = getPaymentDirectPageByPurchaseType(purchaseType);
    if (isNil(page)) return null;

    const url = new URL(window.origin + page.path);

    if (!isNil(startDateOfClass)) url.searchParams.set('start_date_of_class', startDateOfClass);
    return url.toString();
};
