// Local
// const BASE_URL = 'http://localhost:3000';
// export const GRAPHQL_URL = BASE_URL + '/graphql';

const BASE_URL = 'https://english.management';
export const GRAPHQL_URL = BASE_URL + '/dev/graphql';
export const REST_URL = BASE_URL + '/dev';
export const MAX_MOBILE_WIDTH = 734;
export const MAX_TABLET_WIDTH = 1279;
export const MIDDLE_DESKTOP_WIDTH = 1599;
export const MAX_DESKTOP_WIDTH = 1920;
export const MIN_DESKTOP_CONTENT_WIDTH = 600;
export const MAX_DESKTOP_CONTENT_WIDTH = 860;
export const DEVICE = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP',
};

export const GOOGLE_ANALYTICS_CODE = 'UA-100968789-9';
export const GOOGLE_ANALYTICS_4_CODE = 'G-K5RHPG0DFH';

export const PaymentResultStatus = {
    PENDING: 'PENDING',
    SUCCEED: 'SUCCEED',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
    INVALID: 'INVALID',
};

export const MAX_RETRY_COUNT = 3;

export enum ProductBillingType {
    NORMAL = 'NORMAL',
    RECURRING = 'RECURRING',
    MONTHLY_BILLING = 'MONTHLY_BILLING',
}

export const DAYS_OF_MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
export const DEFAULT_BILLING_DAY = 20;
