import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/config';

const PurchaseHeaderSectionWrapper = styled.div`
    padding: 16px 0px;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
`;

const PurchaseHeaderSectionTitle = styled.img`
    display: block;
    width: 480px;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 320px;
    }
`;

export const PurchaseHeaderSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseHeaderSectionWrapper color={material.color}>
            <PurchaseHeaderSectionTitle src={material.desktop.section1} alt="1" />
        </PurchaseHeaderSectionWrapper>
    );
};
