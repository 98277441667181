import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/config';

const PurchaseProcessSectionWrapper = styled.div`
    background: linear-gradient(130.67deg, #222222 10.69%, #26472b 25.89%, #37da4d 102.17%), linear-gradient(0deg, #12b75f, #12b75f),
        linear-gradient(152.01deg, #dffde8 8.69%, #fdf6df 125.16%);
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
        padding: 2rem 1rem;
    }
`;

const PurchaseProcessSectionItem = styled.img`
    display: block;
    width: ${MAX_DESKTOP_CONTENT_WIDTH}px;

    @media (max-width: ${MIDDLE_DESKTOP_WIDTH}px) {
        width: ${MIN_DESKTOP_CONTENT_WIDTH}px;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 100%;
    }
`;

export const PurchaseProcessSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseProcessSectionWrapper>
            <PurchaseProcessSectionItem src={material.desktop.section3} alt="3" />
        </PurchaseProcessSectionWrapper>
    );
};
