import React, { createContext, useEffect, useState } from 'react';
import { getDesktopContentWidth, getDeviceByScreenWidth } from '../utils/screen';
import { IPurchaseConfigure, PurchaseConfigure, PurchaseType } from '../constants/config';
import { DEFAULT_BILLING_DAY } from '../constants/constants';

export const PurchaseDirectInfoContextStore = createContext({} as any);

export const PurchaseDirectInfoContext = (props) => {
    const now = new Date();
    const initialStates = {
        purchaseConfigure: PurchaseConfigure[PurchaseType.MIDDLE_MATH],
        selectedProduct: null,
        products: [],
        productListOnCart: [],
        amount: 0,
        name: '',
        phoneNumber: '',
        payMethod: 'card',
        authCode: '',
        isSentVerifiedCode: false,
        firstDateOfClass: null,
        monthlyBillingDay: DEFAULT_BILLING_DAY,
    };
    const [width, setWidth] = useState(window.innerWidth);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(initialStates.selectedProduct);
    const [productListOnCart, setProductListOnCart] = useState<any[]>(initialStates.productListOnCart);
    const [products, setProducts] = useState(initialStates.products);
    const [name, setName] = useState(initialStates.name);
    const [amount, setAmount] = useState(initialStates.amount);
    const [phoneNumber, setPhoneNumber] = useState(initialStates.phoneNumber);
    const [payMethod, setPayMethod] = useState(initialStates.payMethod);
    const [authCode, setAuthCode] = useState(initialStates.authCode);
    const [isSentVerifiedCode, setIsSentVerifiedCode] = useState(initialStates.isSentVerifiedCode);
    const [firstDateOfClass, setFirstDateOfClass] = useState<Date | null>(initialStates.firstDateOfClass);
    const [monthlyBillingDay, setMonthlyBillingDay] = useState<number | null>(initialStates.monthlyBillingDay);
    const [verifyAuthCodeLoading, setVerifyAuthCodeLoading] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [purchaseConfigure, setPurchaseConfigure] = useState<IPurchaseConfigure>(initialStates.purchaseConfigure as unknown as IPurchaseConfigure);
    const [firstDateOfClassDisabled, setFirstDateOfClassDisabled] = useState<boolean>(false);
    const [monthlyBillingDayDisabled, setMonthlyBillingDayDisabled] = useState<boolean>(true);

    const device = getDeviceByScreenWidth(width);
    const desktopContentWidth = getDesktopContentWidth(width);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        window.addEventListener('scroll', () => setScrollY(window.scrollY));
    }, []);

    const PurchaseInfo = {
        purchaseConfigure,
        setPurchaseConfigure,
        device,
        desktopContentWidth,
        products,
        setProducts,
        selectedProduct,
        setSelectedProduct,
        productListOnCart,
        setProductListOnCart,
        name,
        setName,
        amount,
        setAmount,
        phoneNumber,
        setPhoneNumber,
        payMethod,
        setPayMethod,
        authCode,
        setAuthCode,
        isSentVerifiedCode,
        setIsSentVerifiedCode,
        firstDateOfClass,
        setFirstDateOfClass,
        firstDateOfClassDisabled,
        setFirstDateOfClassDisabled,
        monthlyBillingDay,
        setMonthlyBillingDay,
        monthlyBillingDayDisabled,
        setMonthlyBillingDayDisabled,
        verifyAuthCodeLoading,
        setVerifyAuthCodeLoading,
        scrollY,
        now,
    };

    return <PurchaseDirectInfoContextStore.Provider value={PurchaseInfo}>{props.children}</PurchaseDirectInfoContextStore.Provider>;
};
